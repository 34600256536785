<template>
<div>
  <h1 class="title grey--text my-3">我的账户</h1>
  <v-card flat class="mb-5">
    <v-layout align-center justify-center row wrap class="pa-4">
      <v-flex xs12>
        <div class="caption grey--text">绑定支付宝: {{ linkedExternalFinancialAccountMasked }}
          <v-tooltip top v-if="linkedExternalFinancialAccount">
            <template v-slot:activator="{ on }">
                <v-icon small v-on="on"> remove_red_eye </v-icon>
            </template>
            <span> {{linkedExternalFinancialAccount}} </span>
          </v-tooltip>
        </div>
      </v-flex>
      <v-flex xs6>
        <div class="caption grey--text">账户余额(元)</div>
        <div class="headline">{{ userBalanceYuan ? userBalanceYuan : '-'}}</div>
      </v-flex>
      <v-flex xs6>
        <div class="caption grey--text">动态信用押金(元)
          <v-tooltip top v-if="userFreezingYuan">
            <template v-slot:activator="{ on }">
                <v-icon small v-on="on"> help_outline </v-icon>
            </template>
            <span> 动态更新：包括机构动态押金与承接押金，机构动态押金包括本机构所有在读学员单次活动课费总和的2倍, 且不小于1000元。承接押金为1000元。可前往 “项目及学员>学员” 查看本机构活跃学员列表。</span>
          </v-tooltip>
        </div>
        <div class="headline">{{ userFreezingYuan? userFreezingYuan: '-' }}</div>
      </v-flex>
    </v-layout>
    <v-layout justify-center row wrap>
      <div class="d-flex flex-column">
        <v-btn class="ma-3" color='primary' :disabled="hasExistingRequest" @click="withdrawRequestDialog = true"> {{hasExistingRequest?'申请处理中...':'申请提现'}}  </v-btn>
      </div>
      <v-dialog v-model="withdrawRequestDialog" max-width="400px" right persistent>
        <v-card>
          <v-card-title>
            <span class="headline"> 申请提现 </span>
          </v-card-title>

          <v-card-text>
            <v-form name="withdraw-request-form" ref="withdrawRequestForm" autocomplete='off' v-model="isWithdrawRequestFormVisiblyValid" lazy-validation>
              <v-container>
                <v-text-field
                  label="金额(元)"
                  outlined
                  v-model="withdrawAmountYuan"
                  :rules="withdrawAmountYuanRule"
                ></v-text-field>
              </v-container>
            </v-form>
            <v-alert :value="withdrawRequestErrMsg.length > 0" type="error" outlined>
              {{ withdrawRequestErrMsg }}
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="withdrawDialogClose">关闭</v-btn>
            <v-btn color="primary" @click="withdrawRequest" :disabled="!isWithdrawRequestFormVisiblyValid || !withdrawAmountYuan">申请</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-card>
  <v-card>
    <v-container class="my-5">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <!-- <th class="text-left">类别</th> -->
              <th class="text-left">收入(元)</th>
              <th class="text-left">学员</th>
              <th class="text-left">角色</th>
              <th class="text-left">时间</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in userTransactionRecords" :key="`moneyRecord-${item.moneyRecordId}`">
              <!-- <td class="caption">{{ item.category }}</td> -->
              <td class="caption">{{ item.amountFen / 100 }}</td>
              <td class="caption">{{ item.student }}</td>
              <td class="caption">{{ item.role }}</td>
              <td class="caption">{{ item.dateTime }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
    <v-layout justify-center class="pa-5">
      <v-btn
        text
        outlined
        color="grey"
        :loading="loading"
        :disabled="loading || !userTransactionRecordsPaging.moreData"
        @click="pushNewRecords()">
        加载更多
        <template v-slot:loader>
          <span class="custom-loader">
            <v-icon light>cached</v-icon>
          </span>
        </template>
      </v-btn>
    </v-layout>

  </v-card>

  <!-- display overlay on the whole page while loading -->
  <v-overlay :value="$store.state.queryingState">
    <v-progress-circular
      indeterminate
      color=""
    ></v-progress-circular>
  </v-overlay>
</div>
</template>

<script>
import { UserFinance } from '@/models/FinanceModel'
import { format } from 'date-fns'
import util from '@/util'
const userFinance = new UserFinance()
export default {
  components: {
  },
  computed: {
    hasExistingRequest () {
      if (this.pendingWithdrawRequests.length > 0) {
        return true
      } else {
        return false
      }
    },
    linkedExternalFinancialAccountMasked: function () {
      if (this.linkedExternalFinancialAccount) {
        return util.maskString({ str: this.linkedExternalFinancialAccount })
      } else {
        return null
      }
    }
  },
  data () {
    return {
      userTransactionRecordsPaging: {},
      userTransactionRecords: [],
      pendingWithdrawRequests: [],
      userBalanceYuan: 0,
      userFreezingYuan: 0,
      linkedExternalFinancialAccount: '-',
      withdrawRequestDialog: false,
      withdrawAmountYuan: null,
      withdrawAmountYuanRule: [
        value => !!value || '请填写金额',
        value => {
          const pattern = /^(\d{1,3},?(\d{3},?)*\d{3}(\.\d{0,2})?|\d{1,3}(\.\d{0,2})?|\.\d{1,2}?)$/
          return pattern.test(value) || '请输入正确的金额'
        },
        value => {
          return parseInt(value) >= 1000 || '最低提现金额为1000'
        }
      ],
      isWithdrawRequestFormVisiblyValid: true,
      err: null,
      withdrawRequestErrMsg: '',
      loading: false,
      userInfo: {}
    }
  },
  async mounted () {
    // set queryingState
    this.$store.dispatch('setQueryingState', true)
    // 取得用户余额
    const resUserBalance = await userFinance.financialInfo({
      getBalance: true,
      getPendingWithdrawRequests: true
    })
    console.log(resUserBalance)
    this.userBalanceYuan = resUserBalance.balanceFen / 100
    this.userFreezingYuan = resUserBalance.freezingFen / 100
    this.linkedExternalFinancialAccount = resUserBalance.linkedExternalFinancialAccount
    this.pendingWithdrawRequests = resUserBalance.pendingWithdrawRequests
    // get userTransactionRecords
    this.userTransactionRecordsPaging = UserFinance.getTransactionRecordsPaging() // 返回一个 Paging 类
    const resPaging = await this.userTransactionRecordsPaging.getMoreData()
    if (resPaging) {
      this.userTransactionRecords.push(...this.renderRecords(resPaging.items))
    }
    // set queryingState
    this.$store.dispatch('setQueryingState', false)
  },
  methods: {
    renderRecords (records) {
      records.forEach(record => {
        record.dateTime = format(new Date(record.timestamp), 'yy-MM-dd HH:mm:ss')
      })
      return records
    },
    async pushNewRecords () {
      const resPaging = await this.userTransactionRecordsPaging.getMoreData()
      console.log('请求数据得到 resPaging')
      console.log(resPaging)
      this.userTransactionRecords.push(...this.renderRecords(resPaging.items))
    },
    async withdrawRequest () {
      console.log(`withdraw request submitted ...`)
      const amountFenReq = parseInt(parseFloat(this.withdrawAmountYuan) * 100 * -1)
      try {
        const res = await userFinance.withdrawRequest(amountFenReq)
        console.log(res.msg)
        this.$router.go()
      } catch (err) {
        this.err = err
        console.log(`err: ${err.response.data.msg}`)
        this.withdrawRequestErrMsg = err.response.data.msg[0]
      }
    },
    withdrawDialogClose () {
      this.withdrawRequestDialog = false
      this.withdrawAmountYuan = null
      this.withdrawRequestErrMsg = ''
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
