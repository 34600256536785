import { Paging } from '../util/paging'
import { Http } from '../util/http'

class UserFinance extends Http {
  static getTransactionRecordsPaging (data = {}) {
    return new Paging(
      {
        url: 'finance/user-transaction-records',
        data
      },
      19
    )
  }
  /**
   * 查询用户账户余额 {balanceFen: number, freezingFen: number}
   */
  async balanceInquiry () {
    const res = await this.request().get('finance/user-account-balance', {
      params: {}
    })
    return res.data
  }

  async financialInfo ({ getFreezingAmountDetails, getBalance, getAccumulatives, getPendingWithdrawRequests }) {
    const res = await this.request().get('finance/user-financial-info', {
      params: { getFreezingAmountDetails, getBalance, getAccumulatives, getPendingWithdrawRequests }
    })
    return res.data
  }

  async withdrawRequest (amountFenReq) {
    const res = await this.request().post('finance/user-withdraw-request', {
      amountFenReq: Math.abs(amountFenReq) * -1 // 转化为负值
    })
    return res.data
  }
}

export { UserFinance }
