import { Http } from './http'

/**
 * 分类数据接口类, 该类会以GET方式向后台(由req.url指定的end point)请求及管理分页数据。
 */
class Paging extends Http {
  // public field declarations
  start; // 下次请求的偏移
  count; // 每页所请求的数据条数
  req; // {url: end point, data: 额外参数}
  locker = false; // 数据锁(正在请求会锁住)
  url; // end point
  data = {}; // 额外的请求参数
  moreData = true; // 是否还有更多数据
  accumulator = []; // 已获得的数据存储

  constructor (
    req,
    count = 10,
    start = 0
  ) {
    super() // super must be called before using "this" in inheritated class.
    this.start = start
    this.count = count
    this.req = req
    this.url = req.url
    this.data = req.data
  }

  // 获取更多数据
  async getMoreData () {
    // step1: getLocker
    if (!this.moreData) {
      // 如果没有更多的数据了，则不需要再去请求，直接返回
      return null
    }
    if (!this._getLocker()) {
      // 得到数据锁状态，如果没有上锁则加锁。如果数据锁已锁死，则说明请求正在进行中，需要等待结果，不需要再去请求，直接返回
      return null
    }
    // step2: request
    const data = await this._actualGetData()
    // step3: releaseLocker
    this._releaseLocker()
    return data
  }

  // 实际获得数据
  async _actualGetData () {
    // step 1: 在传入的url上加入 offset 与 limit
    const req = this.req
    // step 2: 通过HTTP类进行数据请求
    let res = await this.request().get(req.url, {
      params: { ...this.data, paging: true, limit: this.count, offset: this.start }
    }) // 进行HTTP请求
    const paging = res.data
    // step 3: 分析数据并对paging类中的变量进行修改
    if (!paging) {
      // 如果没有返回值，则返回 null
      return null
    }
    if (paging.totalRecrodNumber === 0) {
      // 如果有返回但为空，则返回 null
      return {
        empty: true,
        // 当前请求的数据
        items: [],
        moreData: false,
        // 请求的数据累加
        accumulator: []
      }
    }

    this.moreData = Paging._moreData( // 判断是否有更多数据
      paging.totalPageNumber,
      paging.currentPageNumber
    )
    if (this.moreData) { // 更新新数据起点
      this.start += this.count
    }
    this._accumulate(paging.items) // 存储新获得的数据
    return {
      empty: false,
      items: paging.items,
      moreData: this.moreData,
      accumulator: this.accumulator
    }
  }

  _accumulate (items) {
    this.accumulator = this.accumulator.concat(items)
  }

  static _moreData (totalPage, pageNum) {
    return pageNum < totalPage - 1
  }

  // 自定义获取url函数: axio doesn't need this, params is placed seperately.
  _getCurrentReq () {
    // 在已有url基础上增加 offset 和 limit
    let url = this.url
    const params = `offset=${this.start}&limit=${this.count}&paging=true`
    if (url.includes('?')) {
      url += '&' + params
      // contains
    } else {
      url += '?' + params
    }
    this.req.url = url
    return this.req
  }

  _getLocker () {
    // 得到数据锁状态，如果没有上锁则加锁。
    if (this.locker) {
      return false
    }
    this.locker = true
    return true
  }

  _releaseLocker () {
    this.locker = false
  }
}

export { Paging }
